<template>
  <div class='container'>
    <div class='card'>
      <div class='card-header'>
        <h5 class='card-title mb-0'>{{ translations.tcProcessSuccessful }}</h5>
      </div>
      <div class='card-body'>
        <p class='card-text'>{{ thanks }}</p>
        <div class='row align-items-center'>
          <div class='col'>
            <b-button @click='handleReturnHomeClick' variant='primary'>{{ translations.tcReturnHome }}</b-button>
          </div>
          <div class='col text-right text-right'>
            <b-button @click='handleAddAnotherClick' variant='primary'>{{ translations.tcAddAnotherProspect }}
            </b-button>
          </div>
        </div>
      </div>
      <div class='card-footer'>
        <div class='row align-items-center'>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prospect-success',
  mixins: [translationMixin],
  data () {
    return {
      translations: {},
      thanks: ''
    }
  },
  async created () {
    try {
      await this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations()
      ]).then(
        (results) => {
          this.thanks = this.translations.tcMessageOfThanks.replace('{{name}}',this.CandidateThankYouName)
        })
    } catch (e) {
      console.error(e)
    } finally {
      await this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    handleAddAnotherClick () {
      const routeData=this.$route.path.split('/')
      const sponsorType=routeData[4]
      const SPONSOR_ROUTE_NAMES = {
        auxiliary: 'sponsorauxiliarycandidate',
        gideon: 'sponsorcandidate'
      }
      const routeName= SPONSOR_ROUTE_NAMES[sponsorType]
      routeName && this.$router.push({name: routeName })
    },
    handleReturnHomeClick () {
      this.$router.push({
        name: 'membership'
      })
    }
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      CandidateThankYouName: 'candidateModule/CandidateThankYouName'
    })
  }
}
</script>

<style lang="scss">
.dollarsign {
  position: relative;
  height: 0;
  left: -14px;
}
.nonew {
  padding: 30px;
}
.dtext,
.shorttext,
.mediumtext {
  line-height: 13px;
  font-size: 12px;
}
.rtext {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
.shorttext {
  margin-top: 8px;
}
.mediumtext {
  margin-top: 4px;
}
.brow {
  line-height: 36px;
}
.editing {
  background-color: #35b130;
  color: #ffffff;
  border-radius: 6px;
}
.subtitle {
  margin: 10px 0;
}
@import '@/styles/settings.scss';
.membership {
  .page-body {
    margin-top: 60px;
    h2 {
      margin-bottom: 36px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 52px;
    > .row {
      margin-bottom: 40px;
    }
    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }
    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }
      table {
        width: 100% !important;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
